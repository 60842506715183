import React from 'react';
import './header.css';

const Header = () => {
    return(
        <div id='header' >
            <div className='container' style={{textAlign: 'center' }}>
                <div className='headerTitle row'>
                    <div className='title col'>
                        <h1>Good Stewardship Community Endowment</h1>
                    </div>
                </div>
                <div className='headerNav row'>
                    <div className='col link' onClick={ () => window.location='/' } >
                        Home
                    </div>
                    <div className='col link' onClick={ () => window.location='/donate' } >
                        Donate
                    </div>
                    <div className='col link' onClick={ () => window.location='/grants' } >
                        Grants
                    </div>
                    <div className='col link' onClick={ () => window.location='/about' } >
                        About
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Header;