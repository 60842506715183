import React, { useRef, useState } from 'react';
import './grants.css';
import Header from '../../components/header/header';
import Footer from '../../components/footer/footer';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input/input'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import emailjs from '@emailjs/browser';

const Grants = () => {
    const formRef = useRef();
    const [render, setRender] = useState(false);
    const [renderInvolved, setRenderInvolved] = useState(false);
    const [startDate, setStartDate] = useState(new Date());
    const [fullContactName, setFullContactName] = useState('');
    const [, setOrganizationPhoneNumber] = useState('');
    const [organizationEmail, setOrganizationEmail] = useState('');
    const [activitiesList, setActivitiesList] = useState([
        {activity: '',
        timeTable: '',
        },
    ]);
    const [emailMessage, setEmailMessage] = useState("");

    const handleEmail = () => {
        const regEx = /[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,8}(.[a-z{2,8}])?/g;
        if (regEx.test(organizationEmail)) {
            setEmailMessage("Email is Valid");
          } else if (!regEx.test(organizationEmail) && organizationEmail !== "") {
            setEmailMessage("Email is Not Valid");
          } else {
            setEmailMessage("Unknown!");
          }
          console.log(emailMessage)
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        handleEmail();
        emailjs.sendForm("service_gzvagsb","template_3xt8ftu", formRef.current, 'L6KIyZ4KedkLXQznt')
        .then((result) => {
            console.log(result.text);
            console.log('sent!')
        }, (error) => {
            console.log(error.text);
        });
    }

    const handleChange = (e) => {
        if(e.target.value === 'Other'){
            setRender(true);
        }
        else {
            setRender(false);
        }
    }

    const handleInvolvedChange = (e) => {
        if(e.target.value === 'yes'){
            setRenderInvolved(true);
        }
        else {
            setRenderInvolved(false);
        }
    }

    const handleActivityAdd = () => {
        setActivitiesList([...activitiesList, {activity: ''}]);
    }

    const handleActivityRemove = (index) => {
        const list = [...activitiesList];
        list.splice(index, 1);
        setActivitiesList(list);
    }

    const handleActivityChange = (e, index) => {
        const {name, value} = e.target;
        const list = [...activitiesList];
        list[index][name] = value;
        setActivitiesList(list);
    }

    return(
        <div>
            <Header />
                <div id='grants' style={{ padding: '2%' }}>
                    <h1 style={{textAlign: 'center'}}>- Grants -</h1>
                    <h5 style={{textAlign: 'center', padding: '2%'}}>The Good Stewardship Community Endowment is committed to funding 
                        worthy causes in line with our mission statement and values. If you would like to propose a 
                        project for a grant review please complete the form below. After submission your form will be 
                        reviewed in 1 to 2 weeks and we will contact you with more details about the review process.</h5>
                    <form ref={formRef} onSubmit={handleSubmit}>
                        <div className='row mx-auto pt-5'>
                            <div className='col-7 form-group mx-auto'>
                                <p>Organization name</p>
                                <input type='text' className='form-control' onChange={(e) => (e.target.value)} name='organization_name' required />
                            </div>
                            <div className='col-7 form-group mx-auto'>
                                <p>When was your orgaization established?</p>
                                <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} maxDate={new Date()} name='organization_date' />
                            </div>
                            <div className='col-7 form-group mx-auto'>
                                <p style={{ margin: '0'}}>Full name of contact person </p>
                                <p style={{ fontStyle: 'italic' }}>Including title (Dr/Mr/Mrs/Ms/Rev)</p>
                                <input type='text' value={fullContactName} className='form-control' onChange={(e) => setFullContactName(e.target.value)} name='contact_name' required />
                            </div>
                            <div className='col-7 form-group mx-auto'>
                                <p>Full postal address of organization</p>
                                <input type='text' className='form-control' onChange={(e) => (e.target.value)} name='organization_postal_address' required />
                            </div>
                            <div className='col-7 form-group mx-auto'>
                                <p>Full physical address of organization (if different from postal address)</p>
                                <input type='text' className='form-control' onChange={(e) => (e.target.value)} name='organization_physical_address' />
                            </div>
                            <div className='col-7 form-group mx-auto'>
                                <p style={{ margin: '0'}}>Telephone number</p>
                                <p style={{ fontStyle: 'italic' }}>Including country and city codes</p>
                                <PhoneInput country="US" placeholder="Enter phone number" onChange={setOrganizationPhoneNumber} name='organization_phone_number' required />
                            </div>
                            <div className='col-7 form-group mx-auto'>
                                <p>Email</p>
                                <input type='email' className='form-control' onChange={(e) => setOrganizationEmail(e.target.value)} name='organization_email' required />
                            </div>
                            <div className='col-7 form-group mx-auto'>
                                <p>Website (if applicable)</p>
                                <input type='text' className='form-control' onChange={(e) => (e.target.value)} name='organization_website'/>
                            </div>
                            <div className='col-7 form-group mx-auto'>
                                <p>Main aims and objectives of your organization</p>
                                <p style={{ float: 'left', fontSize: '80%'}}>a) What does your organization exist to achieve?</p>
                                <textarea rows='5' className='form-control' onChange={(e) => (e.target.value)} name='organization_goals' required/>
                                <p style={{ float: 'left', fontSize: '80%'}}>b) Who are the key target groups you work with?</p>
                                <textarea rows='5' className='form-control' onChange={(e) => (e.target.value)} name='organization_groups' required />
                                <p style={{ float: 'left', fontSize: '80%'}}>c) Vision, mission or values statement (if applicable)</p>
                                <textarea rows='5' className='form-control' onChange={(e) => (e.target.value)} name='organization_vision' required />
                            </div>
                            <div className='col-7 form-group mx-auto'>
                                <p style={{ margin: '0'}}>How does your organization fund its activities?</p>
                                <p style={{ fontStyle: 'italic' }}>Please include other donors and local contribution (if applicable)</p>
                                <textarea rows='5' className='form-control' onChange={(e) => (e.target.value)} name='organization_funding' required />
                            </div>
                            <div className='col-7 form-group mx-auto'>
                                <p>How would you describe your organization?</p>
                                <select className='form-control' onChange={handleChange} name='organization_description' required>
                                    <option value=''>Please select an option</option>
                                    <option value='NGO'>Non-Governmental organization (NGO)</option>
                                    <option value='CBO'>Community-based organization (CBO)</option>
                                    <option value='Other'>Other (please specify)</option>
                                </select>
                                <div style={{display: render ? 'inherit' : 'none'}}>
                                    <textarea rows='5' placeholder='specify here' className='form-control' onChange={(e) => (e.target.value)} name='organization_description'/>
                                </div>
                            </div>
                            <div className='col-7 form-group mx-auto'>
                                <p style={{ margin: '0'}}>Title of project</p>
                                <p style={{ fontStyle: 'italic' }}>Title should tell the focuse of the project. Please keep it brief and simple</p>
                                <input type='text' className='form-control' onChange={(e) => (e.target.value)} name='project_title' required/>
                            </div>
                            <div className='col-7 form-group mx-auto'>
                                <p>Area of implementation</p>
                                <p style={{ float: 'left', fontSize: '80%'}}>a) Name of country, district, or villages</p>
                                <input type='text'className='form-control' onChange={(e) => (e.target.value)} name='project_location' required/>
                                <p style={{ float: 'left', fontSize: '80%'}}>b) Explain why this area was selected</p>
                                <textarea rows='5' className='form-control' onChange={(e) => (e.target.value)} name='project_area_reasoning' required/>
                            </div>
                            <div className='col-7 form-group mx-auto'>
                                <p>Summary of the project in less than 100 words</p>
                                <textarea rows='5' className='form-control' onChange={(e) => (e.target.value)} name='project_summary' required/>
                            </div>
                            <div className='col-7 form-group mx-auto'>
                                <p>Project framework</p>
                                <p style={{ float: 'left', fontSize: '80%'}}>a) Project aim - What is the overall change to which this project will contribute?</p>
                                <input type='text'className='form-control' onChange={(e) => (e.target.value)} name='project_aim' required/>
                                <p style={{ float: 'left', fontSize: '80%'}}>b) Project objectives - What are the intended achivements of this project</p>
                                <textarea rows='5' className='form-control' onChange={(e) => (e.target.value)} name='project_objectives' required/>
                                <p style={{ float: 'left', fontSize: '80%'}}>c) Project outcomes - What will be the changes you expect to see taking place for individuals, communities, and organizations?</p>
                                <textarea rows='5' className='form-control' onChange={(e) => (e.target.value)} name='project_outcomes' required/>
                                <p style={{ float: 'left', fontSize: '80%'}}>d) Project activities - Please list the activities that will take place in order to meet the objectives and indicate when they will take place</p>
                                <div className='activitiesLabel'>
                                    <div className='row' style={{ display: 'flex', textAlign: 'center' }}>
                                        <p style={{ width: '50%'}}>Activity</p>
                                        <p style={{ width: '50%'}}>Timetable</p>
                                    </div>
                                </div>
                                {activitiesList.map((singleActivity, index) => (
                                    <div key={index} className='my-2'>
                                        <div className='row'>
                                            <div className='col'>
                                                <input type='text' className='form-control' name='activity' required
                                                    onChange={(e) => handleActivityChange(e, index)}
                                                />
                                            </div>
                                            <div className='col'>
                                                <input type='text' className='form-control' name='timeTable' required
                                                    onChange={(e) => handleActivityChange(e, index)}
                                                />
                                            </div>
                                            {activitiesList.length !== 1 && (
                                                <button type='button' className='remove-btn' onClick={() => handleActivityRemove(index)} style={{borderRadius: '5%', border: '1px solid red', backgroundColor: 'transparent', color: 'red'}}>
                                                    <span>X</span>
                                                </button>
                                            )}
                                        </div>
                                        {activitiesList.length - 1 === index && (
                                            <button type='button' className='add-btn' onClick={handleActivityAdd} style={{ backgroundColor: 'transparent', color: '#47B5FF', border: '1px solid #47B5FF', margin: '2%', borderRadius: '5%'}}>
                                                <span>Add Activity</span>
                                            </button>
                                        )}
                                    </div>
                                ))}
                            </div>
                            <div className='col-7 form-group mx-auto'>
                                <p style={{ margin: '0'}}>Who are the intended beneficiaries of this project?</p>
                                <p style={{ fontStyle: 'italic' }}>Please include approximate numbers, gender, age, and any other relevant categorism. Specify direct and indirect beneficiareis</p>
                                <textarea rows='5' className='form-control' onChange={(e) => (e.target.value)} name='project_beneficiaries' required/>
                            </div>
                            <div className='col-7 form-group mx-auto'>
                                <p>Will other organizations be involved in this project? If so, how?</p>
                                <input type="radio" value='yes' onChange={handleInvolvedChange} id="organization_yes" name="organization_involved" required/>
                                <label style={{color: 'black', opacity: '1', margin: '1%'}} htmlFor="html">Yes</label><br/>
                                <input type="radio" value='no' onChange={handleInvolvedChange} id="organization_no" name="organization_involved"/>
                                <label style={{color: 'black', opacity: '1', margin: '1%'}} htmlFor="html">No</label><br/>
                                <div style={{display: renderInvolved ? 'inherit' : 'none'}}>
                                    <textarea rows='5' placeholder='specify here' className='form-control' onChange={(e) => ('Yes: ' + e.target.value)} name='organization_involved'/>
                                </div>
                            </div>
                            <div className='col-7 form-group mx-auto'>
                                <p style={{ margin: '0'}}>How will the project be sustained?</p>
                                <p style={{ fontStyle: 'italic' }}>Explain how the project or its impact will continue after the funding period has ended</p>
                                <textarea rows='5' className='form-control' onChange={(e) => (e.target.value)} name='organization_sustainability' required />
                            </div>
                            <div className='col-7 form-group mx-auto'>
                                <p>Estimated cost of the project</p>
                                <input type='number' className='form-control' onChange={(e) => ('$' + e.target.value)} name='project_cost' required/>
                            </div>
                        </div>
                        <div className='row'>
                            <button className='submit_btn mx-auto'>Submit!</button>
                        </div>
                    </form>
                </div>
            <Footer />
        </div>
    );
};

export default Grants;