import React from 'react';
import tinyTree from '../../../../components/img/tinyTree.jpeg';
import gardening from '../../../../components/img/gardening.jpeg';
import plantingTree from '../../../../components/img/plantingTree.jpeg';
import './carousel.css'

const MainCarousel = () => {
    return(
        <div id='carouselMain'>
            <div id="carouselExampleControls" className="carousel slide" data-ride="carousel" data-interval='3000'>
                <div className="carousel-inner">
                    <div className="carousel-item active" >
                        <img className="d-block w-100" src={tinyTree} alt="First slide" data-interval='3000'/>
                    </div>
                    <div className="carousel-item" >
                        <img className="d-block w-100" src={gardening} alt="Second slide" data-interval='3000' />
                    </div>
                    <div className="carousel-item">
                        <img className="d-block w-100" src={plantingTree} alt="Third slide" data-interval='3000'/>
                    </div>
                </div>
                <a className="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="sr-only">Previous</span>
                </a>
                <a className="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="sr-only">Next</span>
                </a>
            </div>
        </div>
    );
};

export default MainCarousel;