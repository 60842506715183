import React from 'react';
import './donateButton.css';

const DonateButton = () => {
    return(
        <div style={{ textAlign: 'center' }}>
            <h1>- Donate Today! -</h1>
            <div id='donateButton'>
                <button type='button' onClick={ () => window.location = '/donate'}>Donate!</button>
            </div>
        </div>
    );
};

export default DonateButton;