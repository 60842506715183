import React from 'react';
import './about.css';
import Header from '../../components/header/header';
import Footer from '../../components/footer/footer';

const About = () => {
    return(
        <div>
            <Header />
                <div id='about'>
                    <div className='aboutContainer' >
                        <div className='aboutTitle' style={{ padding: '3%' }}>
                            <h1 style={{textAlign: 'center'}}>- About Us -</h1>
                        </div>
                        <p> The Good Stewardship Community Endowment provides funding and support to individuals, 
                            neighborhoods, charities, and organizations with projects that increase decarbonization, 
                            and community interaction as a means to mitigate the effects of climate change. The 
                            community includes both man and nature, air and water.</p>
                        <p>Here are some examples of the kinds of projects we help to support:</p>
                        <ul>
                            <li>Within a neighborhood, a three-block area has organized to plant trees whose canopies 
                                will overhang the street. This will provide numerous benefits, including cooling of 
                                the pavement and providing a more comfortable walking area on hot days. As the area is 
                                made to be more walkable, it becomes easier for neighbors to engage with each other, 
                                forming the type of bonds and connections that increase survival rates during disaster.</li>
                            <li>A local church has a large parking lot that is, on many days, mostly empty. They decide 
                                to install charging stations, and to make them publicly available on the days when they
                                 do not need all of their parking lot.</li>
                            <li>Neighbors living on the main road leading to the local high school organize to provide 
                                pathways for the students to get to school. Their city is unable to provide public 
                                sidewalks, so the nearby residents would like to create a connected path of simple 
                                flagstone walkways at the end of their properties. This project would decrease automobile 
                                traffic, a major source of pollution, and increase the health of the young students- as 
                                well as anyone else who might take advantage of the increased walking area.</li>
                        </ul>
                        <p>Do you have an idea that could both mitigate climate change and strengthen the ties in your 
                            community? If so, please apply for The Good Stewardship Community Endowment and let us know 
                            how we might support you.</p>
                        <p>Would you like to support our work toward building community climate health, and fostering 
                            community engagement? We gratefully welcome donations of any amount.</p>
                    </div>
                </div>
            <Footer />
        </div>
    );
};

export default About;