import React from 'react';
import './contact.css';
import emailjs from '@emailjs/browser';
import { useRef, useState } from 'react';

const Contact = () => {
    const contactFormRef = useRef();
    const [done, setDone] = useState(false);

    const handleContactSubmit = (e) => {
        e.preventDefault();
        console.log('here');
        emailjs.sendForm("service_gzvagsb","template_472mpu8", contactFormRef.current, 'L6KIyZ4KedkLXQznt')
        .then((result) => {
            setDone(true)
            console.log(result.text);
        }, (error) => {
            console.log(error.text);
        });
        e.target.reset();
    }

    return(
        <div style={{padding: '2%'}}>
            <h3>Contact Us!</h3>
           <div className='contactForm'>
                <form ref={contactFormRef} onSubmit={handleContactSubmit}>
                    <input type='text' style={{width: '90%', margin: '1%'}} placeholder='name' onChange={(e) => e.target.value} name='contact_name' required />
                    <input type='email' style={{width: '90%', margin: '1%'}} placeholder='email' onChange={(e) => e.target.value} name='contact_email' required />
                    <textarea rows='5' style={{width: '90%', margin: '1%'}} placeholder='message' onChange={(e) => e.target.value} name='contact_message' required />
                    <button className='contactFormSubmitButton' style={{margin: '1%'}}>Submit!</button>
                </form>
                <div className="sent-message" style={{margin: '1%'}}>
                    {done ? (
                        <p>Sent! Thank you for your message</p>
                    ) : (
                        <p></p>
                    )}
                </div>
           </div>
        </div>
    );
};

export default Contact;