import React from 'react';
import Footer from '../../components/footer/footer';
import Header from '../../components/header/header';

const DonationSuccess = () => {
    return(
        <div style={{height: '100vh'}}>
            <Header/>
            <div id='donationSuccess' style={{height: '100%', textAlign: 'center', padding: '10%'}}>
                <h1>Thank you for your donation!</h1>
                <a href='/'>Back to home →</a>
            </div>
            <Footer/>
        </div>
    );
};

export default DonationSuccess;