import React from 'react';
import { useState } from 'react';
import Contact from '../contact/contact';
import './footer.css';

const Footer = () => {
    const [cookiesToggle, setCookiesToggle] = useState(false);
    const [contactToggle, setContactToggle] = useState(false);

    return(
        <div>
            <div style={{display: cookiesToggle ? 'inherit' : 'none'}}>
                <div className='cookies' style={{borderTop: '1px solid black'}}>
                    <div className='cookiesDesc'>
                        <h3>Cookies</h3>
                        <p>In order to make sure our site is behaving properly and that you can enjoy using it, 
                            we do not use cookies. We do not:</p>
                        <ul>
                            <li>Monitor browsing preferences in order to make a user’s experience more efficient and to provide visitor statistics for analysis.</li>
                            <li>Use Google Analytics cookies to collect anonymous traffic data, such as page visit information, where the visitors to the site 
                                had come from and the browser and operating systems used. </li>
                            <li>Use of session cookies. (Many websites use these cookies for site functionality and personally identifiable information extraction.) 
                                We do none of that.</li>
                        </ul>
                        <p>In short, you do not have to worry about information tracking, extraction or use outside what you provide in your grant, donation, 
                            or contact us forms.</p>
                    </div>
                    <div className='cookiesClose'>
                        <div className="close-container" onClick={() => setCookiesToggle(false)}>
                            <div className="leftright"></div>
                            <div className="rightleft"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='footerForm' style={{display: contactToggle ? 'inherit' : 'none'}}>
                <div className='contactContainer' style={{display: 'flex', borderTop: '1px solid black'}}>
                    <div className='contactForm'>
                        <Contact/>
                    </div>
                    <div className='contactClose'>
                        <div className="close-container" onClick={() => setContactToggle(false)}>
                            <div className="leftright"></div>
                            <div className="rightleft"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div id='footer'>
                <div className='row' style={{padding: '2%', width: '100%', overflow: 'hidden'}}>
                    <div className='col getInTouch'>
                        <h5>Get in touch</h5>
                        <p>Email: admin@gscendowment.org</p>
                        <p>Phone: (925)-287-0438</p>
                    </div>
                    <div className='col findUs'>
                        <h5>Find Us</h5>
                        <p>Lafayette, CA</p>
                    </div>
                    <div className='col aboutTheSite'>
                        <h5>About this site</h5>
                        <a style={{color: 'black'}} href='https://www.accessibilitychecker.org/audit/?website=https%3A%2F%2Fgscendowment.org&flag=us' rel='audit'>Accessability</a>
                        <p style={{cursor: 'pointer'}} onClick={() => setCookiesToggle(true)}>Cookies</p>
                    </div>
                    <div className='col quickLinks'>
                        <h5>Quick Links</h5>
                        <div><a style={{color: 'black'}} href='/about'>About Us</a></div>
                    <div><a style={{color: 'black'}} href='/donate'>Donate</a></div>
                    <div><a style={{color: 'black'}} href='/grants'>Grants</a></div>
                    <div><p style={{color: 'black', cursor: 'pointer'}} onClick={() => setContactToggle(true)}>Contact</p></div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Footer;