import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Main from './pages/main/main';
import Error404 from './pages/404/error404';
import Donate from './pages/donate/donate';
import About from './pages/about/about';
import Grants from './pages/grants/grants';
import DonationSuccess from './pages/donationSuccess/donationSuccess';

function App() {
  return (
    <Routes>
      <Route path='/' exact element={<Main/>} />
      <Route path='donate' exact element={<Donate/>} />
      <Route path='about' exact element={<About/>} />
      <Route path='grants' exact element={<Grants/>} />
      <Route path='donate/success' exact element={<DonationSuccess/>} />
      <Route path='*' exact element={<Error404/>} />
    </Routes>
  );
}

export default App;
