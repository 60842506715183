import React from 'react';
import './posts.css';
import { postData } from './postData.js';

const Posts = () => {
    return(
        <div id='posts'>
            <div className='postsContainer'>
                {postData.map((data, key) =>  {
                    return (
                        <div className='post' style={{ backgroundColor: '#D0C9C0' }} key={key} >
                            <div className='postImg'>
                                <img src={data.img} alt='' />
                            </div>
                            <div className='postTitle'>
                                <h2 style={{ fontSize: '2vw' }} >{data.title}</h2>
                            </div>
                            <div className='postDescription'>
                                {postData[key].description.map((description, key) => {
                                    return(
                                        <p key={key}>{description}</p>
                                    );
                                })}
                            </div>
                            <a style={{ fontSize: '150%' }} className='postLink' href={data.link} >Visit!</a>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default Posts;