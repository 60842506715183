export const postData = [
    {
        title: 'Why Keeping Mature Forests Intact Is Key to the Climate Fight',
        img: 'https://e360.yale.edu/assets/site/_1260x709_crop_center-center/Trees_shutterstock_142702201_web.jpg',
        description: [
            ''
        ],
        link: 'https://e360.yale.edu/features/why-keeping-mature-forests-intact-is-key-to-the-climate-fight'
    },
    {
        title: 'Want to Survive Climate Change? You’ll Need a Good Community',
        img: 'https://www.architecturalrecord.com/ext/resources/Issues/2017/October/continuing-education/1710-Continuing-Education-Coastal-Resilience-04.jpg?t=1506454289&width=900',
        description: [
            ''
        ],
        link: 'https://www.wired.com/2016/10/klinenberg-transforming-communities-to-survive-climate-change/'
    },
    {
        title: 'Cities are getting hotter, but we can redesign them to keep us cool',
        img: 'https://images.fastcompany.net/image/upload/w_1153,ar_16:9,c_fill,g_auto,f_auto,q_auto,fl_lossy/wp-cms/uploads/2019/07/p-1-90379081-urban-design-for-extreme-heat.webp',
        description: [
            ''
        ],
        link: 'https://www.fastcompany.com/90379081/cities-are-getting-hotter-but-we-can-redesign-them-to-keep-us-cool'
    },
    {
        title: 'Using Trees and Vegetation to Reduce Heat Islands',
        img: 'https://www.epa.gov/sites/default/files/styles/small/public/2014-07/t_v-parking.jpg?itok=JiE8dpLK',
        description: [
            ''
        ],
        link: 'https://www.epa.gov/heatislands/using-trees-and-vegetation-reduce-heat-islands'
    },
    {
        title: 'After another hot summer, here are 6 ways to cool our cities in future',
        img: 'https://images.theconversation.com/files/317250/original/file-20200226-24690-16saofs.jpg?ixlib=rb-1.1.0&rect=52%2C243%2C5803%2C2901&q=45&auto=format&w=1356&h=668&fit=crop',
        description: [
            ''
        ],
        link: 'https://theconversation.com/after-another-hot-summer-here-are-6-ways-to-cool-our-cities-in-future-110817'
    },
]