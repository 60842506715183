import React from 'react';
import Footer from '../../components/footer/footer';
import Header from '../../components/header/header';
import './error404.css'

const Error404 = () => {
    return(
        <div id='error404'>
            <Header />
            <div className='error404Content' style={{ padding: '5%' }} >
                <h1>Error</h1>
                <p>Invalid address</p>
                <a style={{ textDecoration: 'none' }} href='/' alt=''>Back to home →</a>
            </div>
            <Footer />
        </div>
    );
};

export default Error404;