import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js';
import React from 'react';

export default function Paypal() {
    const amountElement = document.getElementById('amount');
    return (
    <PayPalScriptProvider options={{'client-id': 'AcO_Gz2vaDeTFeLOCvibv_Gm-ixidmK8wHJTlYL6sAPkcjTEhChnU00o8t5dg3LMdHRVxnfDtYYXbykH'}}>
        <PayPalButtons 
            createOrder={(data, actions) => {
                return actions.order.create({
                    purchase_units: [
                    {
                        amount: {
                        value: amountElement.value,
                        },
                    },
                    ],
                });
            }}
            onApprove={async (data, actions) => {
                const details = await actions.order.capture();
                const name = details.payer.name.given_name;
                console.log("Transaction completed by " + name);
                window.location = '/donate/success'
            }}
        />
    </PayPalScriptProvider>
    );

  }