import React, {useState} from 'react';
import Footer from '../../components/footer/footer';
import Header from '../../components/header/header';
import Paypal from '../../components/paypal/paypal';
import './donate.css';

const Donate = () => {
    const [checkout, setCheckOut] = useState(false);
    const [userInput, setUserInput] = useState('');

    const checkInput = () => {
        if(userInput === ''){
            alert('Please enter an ammount to donate');
            setCheckOut(false);
        } else if(userInput < 0.01){
            alert('Please enter at least one cents');
            setCheckOut(false)
        } else {
            setCheckOut(true);
        }
    }

    return(
        <div>
            <Header/>
            <div id='donate'>
                <h1>- Donate -</h1>
                <div className='row inputs' style={{margin: '2%', transform: 'scale(1.3)', padding: '3% 0%'}}>
                    <input className='donateInput col-md-4 col-sm-11 ml-auto mr-1' placeholder='enter dollar amount' onChange={(e) => setUserInput(e.target.value)} type='number' id='amount' required/>
                    <button className='donationButton col-md-2 col-sm-11 mr-auto ml-1' onClick={() => {checkInput()}}>Checkout</button>
                </div>
                {checkout ? (
                    <div className='row' style={{padding: '5%'}}>
                        <div className='col'>
                        <Paypal />
                        </div>
                    </div>
                ) : (
                    <div></div>
                )}
            </div>
            <Footer/>
        </div>
    );
};

export default Donate;