import React from 'react';
import Header from '../../components/header/header';
import MainCarousel from './components/carousel/carousel';
import Posts from './components/posts/posts';
import DonateButton from './components/donateButton/dontateButton';
import Footer from '../../components/footer/footer';

const Main = () => {
    return (
        <div>
            <Header />
            <MainCarousel />
            <Posts />
            <DonateButton />
            <Footer />
        </div>
    );
};

export default Main;